import React, { useState, useEffect, memo, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Link from "next/link";
import logo from "public/icons/logo-new.svg";
import logoFull from "public/icons/logo-full.svg";
import { ReactComponent as ProfileIcon } from "public/icons/profile.svg";
import { ReactComponent as HeartIcon } from "public/icons/heart-icon.svg";
import { ReactComponent as ClockIcon } from "public/icons/clock-icon.svg";
import FullChristmasLogo from "public/images/christmas2024/logo_full.svg";
import ShortLogo from "public/images/christmas2024/logo_small.svg";
import { useAuthContext, useAppContext } from "contexts";
import { logoutAuth } from "contexts/Auth/actions";
import { useModalContext } from "contexts/Modal";
import Button from "components/Button";
import { parseMultipleGridIsbns, decodeSearchTerm } from "common/utils";
import { ACCENT } from "common/constants/buttonTypes";
import * as PATH from "common/constants/pathRoutes";
import Anchors from "common/constants/anchors";
import { breakpointsValues } from "common/styles/config";
import { useResourceType } from "common/hooks";
import ModalTypes from "common/constants/modalTypes";
import { getInitialActiveTab } from "components/SearchMain/utils";
import { getDesktopDropdownProMenu, getNavList, getProToolMenuConfig } from "./utils";
import * as S from "./Header.styled";
import { IS_CHRISTMAS } from "../../common/commonConfigs";
const MobileMenu = dynamic(() => import("./subcomponents/MobileMenu"), {
  ssr: false
});
const HeaderProfileMenu = dynamic(() => import("./subcomponents/HeaderProfileMenu"), {
  ssr: false
});
const SearchField = dynamic(() => import("./subcomponents/SearchField"));

/**
 * Application new header
 */

const Header = ({
  isMainPage
}) => {
  const router = useRouter();
  const searchQueryTerm = router.query?.query ? decodeSearchTerm(router.query.query) : "";
  const {
    type
  } = useResourceType();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(searchQueryTerm);
  const [isDesktopDropdownShow, setDesktopDropdownShow] = useState(false);
  const headerLogo = IS_CHRISTMAS ? isMainPage ? FullChristmasLogo : ShortLogo : isMainPage ? logoFull : logo;
  const parsedIsbns = useMemo(() => parseMultipleGridIsbns({
    isbns: searchValue,
    withNotification: false
  }), [searchValue]);
  const desktopProDropdownRef = useRef(null);
  const [state, dispatch] = useAuthContext();
  const [{
    watchlistCounter,
    recentBooksCount
  }, appDispatch] = useAppContext();
  const {
    modalAction
  } = useModalContext();
  const {
    isAuth,
    user: {
      isProUser
    }
  } = state;
  const onMenuClickHandler = () => setMenuOpen(!isMenuOpen);
  const onProfileMenuClickHandler = () => setProfileMenuOpen(!isProfileMenuOpen);
  const proToolMenuConfig = useMemo(() => getProToolMenuConfig({
    isProUser,
    router
  }), [isProUser, router.asPath]);
  const activeTab = useMemo(() => getInitialActiveTab({
    type,
    location: router
  }), [type, router.asPath]);
  const handleLoginModal = useCallback(() => {
    modalAction({
      type: ModalTypes.LOG_IN,
      title: "Log in",
      childrenProps: {
        handleResetPasswordModal,
        handleSignUpModal
      }
    });
  }, []);
  const handleSignUpModal = useCallback(() => {
    modalAction({
      type: ModalTypes.SIGN_UP,
      title: "Sign up",
      childrenProps: {
        handleLoginModal,
        handleSignUpProModal
      }
    });
  }, []);
  const handleResetPasswordModal = useCallback(() => {
    modalAction({
      type: ModalTypes.RESET_PASSWORD,
      title: "Reset Password",
      childrenProps: {
        handleLoginModal
      }
    });
  });
  const handleSignUpProModal = useCallback(() => {
    modalAction({
      type: ModalTypes.SIGN_UP_PRO,
      title: "Sign up for BookScouter Pro"
    });
  }, []);
  const logoutAuthHandler = () => logoutAuth({
    dispatch,
    history: router,
    appDispatch
  });
  useEffect(() => {
    setProfileMenuOpen(false);
  }, [isAuth]);
  useEffect(() => {
    if (isMenuOpen && isProfileMenuOpen) {
      setMenuOpen(false);
    }
  }, [isProfileMenuOpen]);
  useEffect(() => {
    // Close all popups on redirect
    if (isMenuOpen || isProfileMenuOpen) {
      setMenuOpen(false);
      setProfileMenuOpen(false);
    }
    setSearchValue(searchQueryTerm);
  }, [router.asPath, searchQueryTerm]);
  useEffect(() => {
    const listener = event => {
      if ((isProfileMenuOpen || isMenuOpen) && event.target.innerWidth > breakpointsValues.desktopPlusWidth) {
        setMenuOpen(false);
        setProfileMenuOpen(false);
      }
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [isProfileMenuOpen, isMenuOpen]);
  useEffect(() => {
    if (isProUser && desktopProDropdownRef.current) {
      desktopProDropdownRef.current.addEventListener("mouseover", () => setDesktopDropdownShow(true));
      desktopProDropdownRef.current.addEventListener("mouseout", () => setDesktopDropdownShow(false));
    }
    return () => {
      if (desktopProDropdownRef.current) {
        desktopProDropdownRef.current.removeEventListener("mouseover", () => setDesktopDropdownShow(true));
        desktopProDropdownRef.current.removeEventListener("mouseout", () => setDesktopDropdownShow(false));
      }
    };
  }, [isProUser, desktopProDropdownRef.current]);
  return <>
            <S.HeaderBase id={Anchors.HEADER} data-test-id="Header-HeaderBase">
                <S.HeaderWrapper>
                    {/* Mobile elements */}
                    <S.HeaderContentMobile data-test-id="Header-HeaderContentMobile">
                        <S.HeaderMobileSection>
                            <S.MobileContentItem>
                                <Link href={PATH.MAIN} prefetch={false} passHref title="BookScouter">
                                    <S.AppLogoLink>
                                        <S.AppLogo src={logo} alt="BookScouter logo" loading="lazy" data-test-id="Header Logo Link" />
                                    </S.AppLogoLink>
                                </Link>
                            </S.MobileContentItem>
                            <S.MobileContentItem>
                                <S.Nav>
                                    <S.NavList>
                                        {getNavList({
                    searchTab: type,
                    searchValue,
                    router,
                    isProUser,
                    isMainPage,
                    parsedIsbns,
                    recentlyViewedLink: PATH.RECENT
                  })}
                                    </S.NavList>
                                </S.Nav>
                            </S.MobileContentItem>
                            {!isMainPage && <S.HeaderTabletSearch>
                                    <SearchField searchValue={searchValue} setSearchValue={setSearchValue} activeTab={activeTab} />
                                </S.HeaderTabletSearch>}
                            <S.MobileContentItem>
                                <S.MobileBurgerIconButton name="Mobile Menu" aria-label="Mobile Menu" data-test-id="Header-MobileBurgerIconButton" onClick={onMenuClickHandler}>
                                    <S.BurgerIcon isOpen={isMenuOpen} />
                                    {isAuth && !isMenuOpen && watchlistCounter > 0 && <S.WatchListCounter top={0}>
                                            {watchlistCounter}
                                        </S.WatchListCounter>}
                                </S.MobileBurgerIconButton>
                            </S.MobileContentItem>
                        </S.HeaderMobileSection>
                        <S.HeaderMobileSection>
                            {!isMainPage && <S.HeaderMobileSearch>
                                    <SearchField searchValue={searchValue} setSearchValue={setSearchValue} activeTab={activeTab} />
                                </S.HeaderMobileSearch>}
                        </S.HeaderMobileSection>
                    </S.HeaderContentMobile>
                    {isMenuOpen && <MobileMenu isAuth={isAuth} isProUser={isProUser} loginModalHandler={handleLoginModal} logoutAuthHandler={logoutAuthHandler} signUpModalHandler={handleSignUpModal} onBurgerClickHandler={onMenuClickHandler} router={router} isMainPage={isMainPage} searchTab={type} searchValue={searchValue} watchlistCounter={watchlistCounter} recentlyViewedLink={PATH.RECENT} recentBooksCount={recentBooksCount} />}
                    {/* Desktop elements */}
                    <S.HeaderContent data-test-id="Header-HeaderContent">
                        <S.ContentPart>
                            <S.ContentItem>
                                <Link href={PATH.MAIN} prefetch={false} passHref title="BookScouter">
                                    <S.AppLogoLink>
                                        <S.AppLogo isMainPage={isMainPage} src={headerLogo} alt="BookScouter logo" data-test-id="Header Logo Link" />
                                    </S.AppLogoLink>
                                </Link>
                            </S.ContentItem>
                            <S.ContentItem>
                                <S.Nav>
                                    <S.NavList>
                                        {getNavList({
                    searchTab: type,
                    searchValue,
                    router,
                    isProUser,
                    isMainPage,
                    parsedIsbns
                  })}
                                    </S.NavList>
                                </S.Nav>
                            </S.ContentItem>
                        </S.ContentPart>
                        <S.ContentPart>
                            {!isMainPage && <S.SearchFieldContainer>
                                    <SearchField searchValue={searchValue} setSearchValue={setSearchValue} activeTab={activeTab} />
                                </S.SearchFieldContainer>}
                            <S.HeaderProfile>
                                {isAuth ? <>
                                        {isProUser ? <S.NavLinkWrapper ref={desktopProDropdownRef}>
                                                <Link href={proToolMenuConfig.path} prefetch={false} passHref>
                                                    <S.NavLink data-test-id={proToolMenuConfig.id} className={proToolMenuConfig.isActive && "active"}>
                                                        {proToolMenuConfig.label}
                                                    </S.NavLink>
                                                </Link>
                                                {getDesktopDropdownProMenu({
                    show: isDesktopDropdownShow,
                    config: proToolMenuConfig
                  })}
                                            </S.NavLinkWrapper> : <Link href={proToolMenuConfig.path} prefetch={false} passHref>
                                                <S.NavLink data-test-id={proToolMenuConfig.id} className={proToolMenuConfig.isActive && "active"}>
                                                    {proToolMenuConfig.label}
                                                </S.NavLink>
                                            </Link>}
                                        <Link href={PATH.WATCHLIST_SELL} prefetch={false} passHref>
                                            <S.NavIconLinkTooltip title={"Watchlist"}>
                                                <S.NavIconLink data-test-id={"Watchlist menu link"} className={router.asPath.includes(PATH.WATCHLIST) && "active"}>
                                                    <HeartIcon />
                                                    {watchlistCounter > 0 && <S.WatchListCounter top={2} right={1}>
                                                            {watchlistCounter}
                                                        </S.WatchListCounter>}
                                                </S.NavIconLink>
                                            </S.NavIconLinkTooltip>
                                        </Link>
                                        {!!recentBooksCount && <Link href={PATH.RECENT} prefetch={false} passHref>
                                                <S.NavIconLinkTooltip title={"Recently viewed"}>
                                                    <S.NavIconLink data-test-id={"Recently viewed menu link"} className={router.asPath.includes(PATH.RECENT) && "active"}>
                                                        <ClockIcon fill={"e06239"} />
                                                        <S.WatchListCounter top={2} right={1}>
                                                            {recentBooksCount}
                                                        </S.WatchListCounter>
                                                    </S.NavIconLink>
                                                </S.NavIconLinkTooltip>
                                            </Link>}
                                        <S.HeaderProfileButtonContainer isChristmas={IS_CHRISTMAS}>
                                            {IS_CHRISTMAS && <S.AppLogoTop />}
                                            <S.HeaderProfileButton data-test-id={"Account circle button"} onClick={onProfileMenuClickHandler}>
                                                <ProfileIcon />
                                            </S.HeaderProfileButton>
                                            {isProfileMenuOpen && <HeaderProfileMenu onProfileMenuClickHandler={onProfileMenuClickHandler} logoutAuthHandler={logoutAuthHandler} />}
                                        </S.HeaderProfileButtonContainer>
                                    </> : <>
                                        <Link href={proToolMenuConfig.path} passHref prefetch={false}>
                                            <S.NavLink data-test-id={proToolMenuConfig.id} className={proToolMenuConfig.isActive && "active"}>
                                                {proToolMenuConfig.label}
                                            </S.NavLink>
                                        </Link>
                                        {!!recentBooksCount && <Link href={PATH.RECENT} prefetch={false} passHref>
                                                <S.NavIconLinkTooltip title={"Recently viewed"}>
                                                    <S.NavIconLink data-test-id={"Recently viewed menu link"} className={router.asPath.includes(PATH.RECENT) && "active"}>
                                                        <ClockIcon fill={"e06239"} />
                                                        <S.WatchListCounter top={2} right={1}>
                                                            {recentBooksCount}
                                                        </S.WatchListCounter>
                                                    </S.NavIconLink>
                                                </S.NavIconLinkTooltip>
                                            </Link>}

                                        <Button data-test-id={"Log In button"} variant={ACCENT} label={"Log In"} onClick={handleLoginModal} styles={S.profileLightButtonStyles} />
                                        <Button data-test-id={"Sign Up button"} variant={ACCENT} label={"Sign Up"} onClick={handleSignUpModal} styles={S.profileButtonStyles} />
                                    </>}
                            </S.HeaderProfile>
                        </S.ContentPart>
                    </S.HeaderContent>
                </S.HeaderWrapper>
            </S.HeaderBase>
            {isMenuOpen && <S.HeaderOverlay />}
        </>;
};
Header.propTypes = {
  isMainPage: PropTypes.bool
};
Header.defaultProps = {
  isMainPage: true
};
export default memo(Header);